.service {
  padding: 40px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.service .cardd {
  display: flex;
  height: 400px;
  width: 300px;

  padding: 15px;
  border-radius: 14px;
  box-shadow: 0 16px 25px rgba(0, 0, 0, 0.25), 0 11px 11px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  text-align: center;
  margin: 15px;
  justify-content: space-around;
}
.service .cardd h6 {
  color: #000;
  font-weight: bold;
}
.service .cardd img {
  width: 100%;
  height: 190px;
  border-radius: 13px;
  margin: 0 auto;
  margin-bottom: 16px;
  object-fit: cover;
}
