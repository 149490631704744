.conatact{
    background-color:  #fff;

}
.conatact .info {
  padding-top: 150px;
  padding-bottom: 80px;
  align-items: center;
  background-color:  #fff;
  overflow: hidden;
  height: 100%;
  display: flex;
}

.conatact .info h2 {
  padding-bottom: 20px;
  color: #071c43;
  font-size: 39px;
}
.conatact .info p {
  color: #195191;
  font-size: 21px;
}
.conatact .info .btn {
  border-radius: 0px;
  font-weight: bold;
  margin-top: 20px;
  background-color: #b59e6c;
  padding: 10px 40px 10px 40px;
}
.conatact .info img {
  width: 70%;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}
.conatact .contentt{
    margin-bottom: 41px;
    background-color: #fff;
    padding: 11px;
    /* box-shadow: 0 16px 25px rgba(0, 0, 0, 0.25), 0 11px 11px rgba(0, 0, 0, 0.25); */
    border-radius: 18px;
}
.conatact .cont2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
}
.conatact i{
    font-size: 37px;
    margin:10px
}