.navbar-expand-lg .navbar-nav {
  margin-left: auto;
}
.navbar-brand img {
  width: 100px;
  object-fit: cover;
}
.bg-body-tertiary {
  background-color: rgb(255 255 255) !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  font-size: 18px;
  padding-left: var(--bs-navbar-nav-link-padding-x);
  color: #333 !important;
}
/* .nav-link:hover {
  transition: 0.3s;
  border-bottom: 3px solid #674188 !important;
  border-top: 3px solid #674188 !important;
  
} */
.bg-body-tertiary,
.navbar {
  --bs-bg-opacity: 0 !important;
  background-color: #fff !important;
}
.navbar .active {
  transition: 0.3s;
  border-bottom: 3px solid #674188 !important;
  border-top: 3px solid #674188 !important;
}

.firstnav {
  height: 40px;
  display: flex;
  background-color: #674188;
  color: #fff;
  padding: 7px;
}
.firstnav div {
  margin-right: 40px;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .firstnav {
    padding: 3px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .firstnav div {
    margin-right: 7px;
    margin-left: 1px;
    font-size: 10px !important;
  }
  /* .navbar-brand img {
    width: 80px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  } */
}
.navbar-toggler {
  margin: 0 auto !important;
}
