.slider .slider-caption {
  font-size: 20px;
  text-align: initial;
  font-family: Almarai;
}
.carousel-indicators {
  z-index: 100 !important;
}
.carousel-caption {
  z-index: 9;
}
.slider img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.s {
  position: relative;
}
.s .overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
}
.containImg {
  display: flex;
  justify-content: space-between;
}
.containImg img {
  width: 35%;
  object-fit: cover;
  height: 254px;
}
.Referenzen {
  padding: 30px;
}
.Referenzen .contentre {
  display: flex;
  padding: 21px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.textref{
    margin-bottom: 20px;

}
.Referenzen h1 {
  color: #674188;
  margin-bottom: 20px;
}
.sliderref {
  width: 390px;
}

.carousel-indicators button {
  color: #333 !important;
  opacity: 1 !important;
  background-color: #ddd;
}
.carousel-indicators button.active {
  color: #333 !important;
  opacity: 1 !important;
  background-color: #333;
}
