.whyul {
  list-style: none;
  width: 100%;
  text-align: justify;
  line-height: 1.7;
  font-size: 19px;
}
.whyul i {
  margin-right: 10px;
  color: E2BFD9;
}
.img-galary {
  width: 90%;
  background-color: white;
  margin: 50px auto;
  padding: 10px 0;
  border-radius: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  /* justify-content: space-evenly; */
  /* opacity: 0; */
  animation: opacity 2.5s;
}

.img-galary__coloumn {
  width: 350px;
  height: 450px;
  overflow: hidden;
  margin: 20px;
}

.img-galary__coloumn img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  transition: all 0.5s;
  /* transform: scale(1); */
  object-fit: cover;
  animation: scale 0.2s 0.5s;
  margin: 10px;
}

.img-galary:hover {
  border-radius: 0px;
}

.img-galary:hover img {
  transform: scale(0.95);
  z-index: 99;
}

.img-galary__coloumn img:hover {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.287);
  cursor: pointer;
  transform: scale(1.05);
  border-radius: 20px;
  z-index: 100;
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.vid {
  height: 600px;
  width: 100%;
  margin: 53px auto;
  border-radius: 20px;
}
