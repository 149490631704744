.help{
    background-color:  #eee;

}
.help .info {
  padding-top: 150px;
  padding-bottom: 80px;
  align-items: center;
  background-color:  #eee;
  overflow: hidden;
  height: 100%;
  display: flex;
}

.help .info h2 {
  padding-bottom: 20px;
  color: #071c43;
  font-size: 39px;
}
.help .info p {
  color: #195191;
  font-size: 21px;
}
.help .info p li{
  list-style: none;
  line-height:1.7
}
.help .info p li i{
  margin-right: 5px;
}
.help .info .btn {
  border-radius: 0px;
  font-weight: bold;
  margin-top: 20px;
  background-color: #b59e6c;
  padding: 10px 40px 10px 40px;
}
.help .info img {
  width: 70%;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 16px 25px rgba(0, 0, 0, 0.25), 0 11px 11px rgba(0, 0, 0, 0.25);
  border-radius:20px;

}
.help .contentt{
    
    background-color: #fff;
    padding: 20px;
    border-radius: 18px;
    overflow: hidden;
}