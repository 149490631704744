.DATEREQ {
  display: flex;
  height: 357px;
  background-color: #674188;
  justify-content: space-around;
  flex-wrap: wrap;
}
.DATEREQ .divIcon i{
    font-size: 114px;
    color: white;
    margin-top: 100px;
}
.DATEREQ .divContent {
  margin-top: 100px;
  line-height: 2.7;
  color: #fff;
}
.DATEREQ .divContent button {
  background-color: transparent;
  border: 1px solid;
  color: white;
}
