.SomeLink {
  padding: 48px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.SomeLink .SomeConent {
    display: flex;
    height: 129px;
    width: 276px;
    padding: 15px;
    border-radius: 14px;
    box-shadow: 0 16px 25px rgba(0, 0, 0, 0.25), 0 11px 11px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    text-align: start;
    margin: 15px;
    justify-content: space-around;
}
.SomeLink .SomeConent a {
    text-decoration: none;
}
.SomeLink h2 {
  text-align: center;
  margin: 12px auto;
  width: 100%;
  color: rgb(7, 28, 67);
}
